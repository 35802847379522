

















































import Vue from "vue";
import {
  BtnTranslate,
  BtnFacebook,
  BtnInstagram,
  BtnWhatsapp,
  BtnShowDrawer,
} from "@/components/buttons";

export default Vue.extend({
  name: "app-top-bar",
  components: {
    BtnTranslate,
    BtnFacebook,
    BtnInstagram,
    BtnWhatsapp,
    BtnShowDrawer,
  },
  data() {
    return {
      links: [
        {
          label: "home",
          to: "/home",
        },
        {
          label: "truckList",
          to: "/trucks-list",
        },
        {
          label: "galery",
          to: "/galery",
        },
        {
          label: "contact",
          to: "/contact",
        },
      ],
    };
  },
});
