import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/home",
    name: "Home",
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/Home/Home.vue"),
  },
  {
    path: "/contact",
    name: "Contact",
    component: () =>
      import(/* webpackChunkName: "contact" */ "../views/Contact.vue"),
  },
  {
    path: "/trucks/:brand",
    name: "Trucks by brand",
    component: () =>
      import(
        /* webpackChunkName: "trucks-brand" */ "../views/TrucksByBrand.vue"
      ),
  },
  {
    path: "/truck/:truck",
    name: "Trucks details",
    component: () =>
      import(
        /* webpackChunkName: "trucks-details" */ "../views/TruckDetails.vue"
      ),
  },
  {
    path: "/trucks-list",
    name: "Trucks list",
    component: () =>
      import(/* webpackChunkName: "trucks-details" */ "../views/TruckList.vue"),
  },
  {
    path: "/galery",
    name: "Galery",
    component: () =>
      import(/* webpackChunkName: "galery" */ "../views/Galery.vue"),
  },
  {
    path: "*",
    name: "Error 404",
    component: () => import(/* webpackChunkName: "404" */ "../views/404.vue"),
  },
  {
    path: "/",
    redirect: "/trucks-list",
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const { name } = to;
  if (name == "Trucks by brand" || name == "Trucks list") {
    next();
    return;
  }

  window.scrollTo(0, 0);
  // Scroll to top with animation
  // setTimeout(() => {
  //   window.scrollTo(0, 0);
  // }, 1000);
  next();
});

export default router;
