import { Truck } from "@/models/Truck";

export function mutShowDrawer(state: any, showDrawer: boolean): void {
  state.showDrawer = showDrawer;
}

export function mutTrucks(state: any, trucks: any[]): void {
  state.trucks = trucks;
}

export function mutUpdateTrucks(state: any, trucks: Truck[]): void {
  state.trucks = [...state.trucks, ...trucks];
}

export function mutBrands(state: any, brands: any[]): void {
  state.brands = brands;
}
