import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import i18n from "./i18n";
// MDI icons
import "@mdi/font/css/materialdesignicons.min.css";
import VueAnime from "vue-animejs"; // For animations
// Plugins
import InfiniteLoading from "vue-infinite-loading";

Vue.use(VueAnime);
Vue.use(InfiniteLoading, {
  /* options */
});

import LazyTube from "vue-lazytube";
Vue.use(LazyTube);

// App style
import "@/sass/app.scss";

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
