var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.brands.length > 0)?_c('v-toolbar',{staticClass:"navbar-categories align-self-center position-sticky",class:{
    arrows: _vm.$vuetify.breakpoint.lgAndUp,
  },style:({
    'z-index': 1,
    top: _vm.$vuetify.breakpoint.smAndDown ? '54px' : '64px',
  }),attrs:{"color":"white","tag":"nav"}},[_c('v-spacer'),_c('v-slide-group',{staticClass:"fill-height",attrs:{"multiple":"","show-arrows":""}},[_c('v-slide-item',[_c('div',{staticClass:"d-flex"},[_c('v-btn',{staticClass:"mx-2 fill-height",attrs:{"to":"/trucks-list","exact":"","text":"","tile":""}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t("all"))+" ")])])],1)]),_vm._l((_vm.brands),function(brand,index){return _c('v-slide-item',{key:index},[_c('div',{staticClass:"d-flex"},[_c('v-btn',{staticClass:"mx-2 fill-height",attrs:{"to":{
            name: 'Trucks by brand',
            params: {
              brand: brand._id,
            },
          },"exact":"","text":"","tile":""}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(brand.name)+" ")])])],1)])})],2),(_vm.brands.length == 0)?_c('v-progress-circular',{attrs:{"color":"primary","indeterminate":""}}):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }