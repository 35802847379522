






import { MUT_SHOW_DRAWER } from "@/store/mutations/types";
import Vue from "vue";
import { mapMutations } from "vuex";

export default Vue.extend({
  name: "btn-show-drawer",
  methods: {
    ...mapMutations([MUT_SHOW_DRAWER]),
  },
});
