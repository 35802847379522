import Vue from "vue";
import Vuex from "vuex";
import * as mutations from "./mutations";
import * as actions from "./actions";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    showDrawer: null,
    trucks: [], // The trucks list
    brands: [], // Get trucks by brands
  },
  mutations: mutations,
  actions: actions,
  // modules: {},
});
