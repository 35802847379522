




































































































import Vue from "vue";
import { BtnFacebook, BtnInstagram } from "@/components/buttons";

export default Vue.extend({
  name: "site-footer",
  components: {
    BtnFacebook,
    BtnInstagram,
  },
});
