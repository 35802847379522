import axios from "axios";

const APIUrl = process.env.VUE_APP_BASE_URL;

const axiosBase = axios.create({
  baseURL: APIUrl,
  headers: { "Content-Type": "application/json" },
});
const httpClient = axios.create({
  baseURL: APIUrl,
});

export default httpClient;
export { axiosBase };
