





























































import Vue from "vue";
import { mapState } from "vuex";

export default Vue.extend({
  name: "navbar-categories",
  computed: {
    ...mapState(["brands"]),
  },
});
