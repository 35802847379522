















































































import Vue from "vue";
import { MUT_SHOW_DRAWER } from "@/store/mutations/types";
import { mapMutations, mapState } from "vuex";
import { BtnFacebook, BtnInstagram, BtnTwitter } from "@/components/buttons";

export default Vue.extend({
  name: "menu-drawer",
  components: {
    BtnFacebook,
    BtnInstagram,
    BtnTwitter,
  },
  data() {
    return {
      locale: this.$i18n.locale,
      links: [
        {
          label: "home",
          to: "/home",
        },
        {
          label: this.$t("galery"),
          to: "/galery",
        },
        {
          label: "truckList",
          to: "/trucks-list",
        },
        {
          label: "contact",
          to: "/contact",
        },
        // {
        //   label: "reeferTriler",
        //   to: "#3",
        // },
      ],
    };
  },
  computed: {
    ...mapState(["showDrawer"]),
  },
  methods: {
    ...mapMutations([MUT_SHOW_DRAWER]),
    onInput(state: boolean): void {
      this[MUT_SHOW_DRAWER](state);
    },
  },
});
