import { httpClient } from "@/services";
import { AxiosResponse } from "axios";
import { MUT_BRANDS, MUT_TRUCKS } from "../mutations/types";

export async function actTrucksList({ commit }: any): Promise<any> {
  const response: AxiosResponse = await httpClient("truck");
  const { data, ...pagination } = response.data;

  commit(MUT_TRUCKS, data);
  return pagination;
}

export async function actBrandsList({ commit }: any): Promise<any> {
  const response: AxiosResponse = await httpClient("brand");
  const { data, ...pagination } = response.data;

  commit(MUT_BRANDS, data);
  return pagination;
}

export async function actTruckDetails(
  store: any,
  truckId: string
): Promise<any> {
  const response: AxiosResponse = await httpClient("truck/" + truckId);
  const details = response.data;

  return details;
}

export async function actTrucksByBrand(
  { commit }: any,
  brandId: string
): Promise<any> {
  const response: AxiosResponse = await httpClient("truck?brandId=" + brandId);
  const { data, ...pagination } = response.data;

  commit(MUT_TRUCKS, data);
  return pagination;
}
