














import Vue from "vue";
import { AppTopBar, SiteFooter, MenuDrawer } from "@/components/layout";
import { mapActions } from "vuex";
import { ACT_BRANDS_LIST } from "./store/actions/types";

export default Vue.extend({
  name: "App",
  components: {
    AppTopBar,
    SiteFooter,
    MenuDrawer,
  },
  data: () => ({}),
  // computed: {
  //   viewKey(): string {
  //     let key = `${this.$route.name}-${
  //       this.$route.params.category != undefined
  //         ? this.$route.params.category
  //         : ""
  //     }`;

  //     return key;
  //   },
  // },
  mounted() {
    // console.log(process.env);
    this[ACT_BRANDS_LIST]();
  },
  methods: {
    ...mapActions([ACT_BRANDS_LIST]),
  },
});
